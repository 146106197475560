/* div#signInDiv div#container div{
  width: 97%;
}
div#signInDiv div#container {
  width: 100%;
} */
div#signInDiv iframe {
  margin: 0 auto !important;
}
div#signInDiv {
  margin-top: 12px;
  width: auto;
  /* padding-left: 40px; */
}
/*.swal-icon {
  width: auto;
  height: auto;
  border-bottom: 2px solid var(--nbl-primary);
  margin: 0 14px !important;
}*/
.swal-button {
  background-color: var(--nbl-primary);
  border-radius: 50px;
  width: 100%;
}
.swal-icon img {
  width: 55%;
}
.swal-footer {
  text-align: center;
}
.swal-button-container {
  width: 35%;
}
.swal-icon--error__line--left {
  left: 8px;
}
.fc-daygrid-day-events > div {
  margin-bottom: 2px !important;
}
.swal-modal {
  width: 365px;
  border-radius: 40px;
  border: 1px solid var(--nbl-primary);
}
.swal-icon--error__line--right {
  right: 8px;
}
.swal-icon--error__line {
  position: absolute;
  height: 4px;
  width: 30px;
  top: 20px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: unset;
}

:root {
  --main-nbl: #3da4c2;
  --sec-nbl: #1e5a68;
  --nbl-primary: #10567e;
  --primary-shade: #2e86ab;
  --primary-tint: #80c6db;
  --nbl-secondary: #f9d7c8;
  --secondary-hue: #ef946c;
  --secondary-tone: #c05746;
  --nbl-text: #666;
}

.main {
  display: flex;
}

#primary {
  width: 100%;
  background: #f1f5ff;
  /* background: #88b9c82b; */
  /* background: url(https://www.patternpictures.com/wp-content/uploads/Kristal-subtle-glass-white-background-pattern-patternpictures-0220-1600x1067.png) */
  background: url(https://mcdn.wallpapersafari.com/medium/23/97/1JynjM.jpg)
    #f1f5ff;
  height: auto;
  background-blend-mode: darken;
  background-size: cover;
  /* height: 100vh; */
  overflow: auto;
}

#sidebar {
  width: 20%;
  max-width: 20%;
  box-shadow: 2px 2px 10px #1e5a6833;
  background: transparent;
  min-height: 100vh;
  height: auto;
  z-index: 1;
  position: sticky;
  top: 0;
  height: 100vh;
}

#sidebar span.close-sidebar {
  display: none;
}

#sidebar .nbl-logo {
  padding: 10px 20px;
  text-align: center;
}

#sidebar .nbl-logo img {
  height: 30px;
}

#sidebar h5 {
  text-align: center;
  color: var(--secondary-tone);
}

#sidebar .menu-list-wrapper {
  padding-top: 0.8rem;
}

#sidebar .menu-list-wrapper .menu-list {
  padding-left: 0;
}

#sidebar .menu-list-wrapper .menu-list li.listItem {
  list-style: none;
}

#sidebar .menu-list-wrapper .menu-list li.listItem:hover {
  background: #e1eaff;
  color: var(--sec-nbl);
}

#sidebar .menu-list-wrapper .menu-list li.listItem a {
  width: 100%;
  display: inline-block;
  text-decoration: none;
  padding: 4px 0;
  color: #666;
  padding-left: 2rem;
  font-size: 15px;
}

#sidebar .menu-list-wrapper .menu-list li.listItem a.active {
  color: var(--nbl-primary);
  background: #e1eaff;
  font-weight: 600;
}

#sidebar .menu-list-wrapper .listItem span.labelicon {
  vertical-align: text-bottom;
  padding-right: 0.5rem;
}

#sidebar h5.emp-name {
  color: var(--sec-nbl);
  margin-bottom: 0.2rem;
  font-size: 18px;
}

#sidebar #emp-sect .emp-desig {
  font-size: 14px;
  text-transform: capitalize;
}

#sidebar .copyright {
  font-size: 12px;
  color: #666;
  text-align: center;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 25px;
  width: 100%;
  transform: translateX(-25px);
}

#sidebar .copyright a {
  color: inherit;
  text-decoration: none;
}

#emp-sect ul li a {
  color: var(--primary-shade);
}

#emp-sect .emp-photo {
  height: 110px;
  width: 110px;
  /* border: 1px solid #000; */
  border-radius: 50%;
  display: block;
  margin: 1rem auto 0.5rem;
  object-fit: cover;
}

/* ---------------------- Header  ----------------  */
div#hamburger {
  display: none;
}

#sidebar span#close-sidebar {
  display: none;
}

span.pwd-error {
  text-align: center;
  background: #ff00001a;
  padding: 0.3rem 0;
  border-radius: 4px;
}

span.pwd-success {
  text-align: center;
  background: #0080001c;
  padding: 0.3rem 0;
  border-radius: 4px;
}

#primary .header {
  height: auto;
  background: transparent;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#primary .header #announcement {
  position: absolute;
  left: 45%;
  transform: translateX(-50%);
  /* background: #f9d7c89c; */
  background: #14746f;
  /* color: var(--primary-shade); */
  color: #fff;
  padding: 0.5rem 1rem;
  top: 2rem;
  /* border: 1px solid var(--secondary-hue); */
  border: 1px solid #14746f;
  border-radius: 5px;
  max-width: 55%;
}

#announcement h5 {
  font-size: 15px;
  word-break: break-word;
  margin-bottom: 0;
  text-align: center;
}

#primary .header button {
  border: none;
  background: none;
  padding: 0 10px;
  color: #555;
}

#primary .header button:hover {
  color: #c80808;
}

#primary .header span {
  display: inline-block;
  padding-left: 1rem;
}

#primary .header span.menu-time-container {
  padding-left: 0rem;
}

#primary span.change-pwd {
  color: var(--nbl-primary);
  font-size: 14px;
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
}

#primary .header .empImg {
  height: 35px;
  width: 35px;
  display: inline-block;
  background: transparent;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px #ccc;
  object-fit: cover;
}

#primary .header h4 {
  color: var(--primary-shade);
}

.main-view .nbl-btn {
  background: var(--secondary-tone);
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  text-decoration: none;
  margin-top: 1rem;
}

.change-pswd button.nbl-btn {
  margin-right: 1rem !important;
}

/* Dashboard */
div.main-view {
  padding: 20px;
  /* min-height: calc(100vh - 65px); */
  height: auto;
}

div.main-view .view-head h6 {
  margin: 0;
  text-transform: uppercase;
  color: var(--secondary-tone);
}

div.main-view .view-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 38px;
  height: auto;
}

div#dashboard button.punch-btn {
  background: var(--secondary-tone);
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
}

div#dashboard button.punch-btn span.punch-clock {
  display: inline-block;
  transform: translateY(-2px);
  margin-right: 5px;
}

#dashboard form {
  display: flex;
  align-items: center;
  justify-content: center;
}

#dashboard form input.punch-hrs {
  width: 130px;
  text-align: center;
  background: #fff;
  padding: 5px 10px;
}

#dashboard p.greetings {
  font-size: 24px;
  font-weight: 600;
}

#primary #overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: none;
  z-index: 15;
  background-color: #66666638;
}

.main-view .view-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 225px 180px 210px;
  grid-gap: 15px;
}

#dashboard p.punch-note {
  font-size: 14px;
  line-height: 1.4;
  color: #aaa;
}

#dashboard span.punch-note {
  display: inline-block;
  font-size: 18px;
  color: #666;
  /* color: orange; */
  padding-right: 10px;
  cursor: pointer;
  margin-top: -0.2rem;
}

#dashboard .view-grid #welcome {
  grid-column: span 6;
  background: var(--nbl-primary);
  border-radius: 5px;
  color: #fff;
  padding: 15px 20px;
}

#welcome p.fullDate {
  padding-top: 0.4rem;
}

#dashboard .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#dashboard .view-grid #welcome button.trello {
  background: transparent;
  border: none;
  margin-top: 1rem;
}

#dashboard .view-grid #punch,
#dashboard .view-grid #lvstatus,
#dashboard .view-grid #bday,
#dashboard .view-grid #hday,
#dashboard .view-grid #leave {
  grid-column: span 3;
}

#dashboard .view-grid #hday,
#dashboard .view-grid #leave {
  grid-column: 10/-1;
}

#dashboard .view-grid #welcome button.trello a {
  color: #fff;
  text-decoration: none;
  background: var(--secondary-hue);
  padding: 5px 10px;
  border-radius: 4px;
}

#lvstatus li.meet-details {
  display: flex;
  justify-content: space-between;
}

#lvstatus .meet-list .meet-details a.meet-link {
  text-decoration: none;
  color: var(--primary-shade);
  font-size: 14px;
  font-weight: 600;
}

#bday p.bday-head,
#lvstatus p.lvstatus-head,
#hday p.hday-head,
#leave p.leave-head,
#trello p.trello-head {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
}

#bday .bday-head a,
#hday p.hday-head a,
#leave p.leave-head a,
#lvstatus p.lvstatus-head a {
  text-decoration: none;
  font-size: 12px;
  float: right;
  color: var(--main-nbl);
  cursor: pointer;
}

#bday ul.bday-list,
#lvstatus ul.meet-list,
#hday ul.hday-list,
#leave ul.leave-list,
ul.leave-list {
  padding: 0.5rem 0 0 0;
  list-style: none;
  margin: 0;
}

/*  LEAVE STATUS  */

ul.leave-list .emp-details img.emplv {
  height: 35px;
  width: 35px;
  /* border: 1px solid #eee; */
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  object-fit: cover;
}

ul.leave-list .emp-details {
  display: flex;
  align-items: center;
}

ul.leave-list .emp-details p {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

ul.leave-list li p span.lvemp-designation {
  font-size: 11px;
  font-weight: 400;
  text-transform: capitalize;
}

ul.leave-list li {
  padding-bottom: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

ul.leave-list li span.emp-lv-dt,
.lv-status-task ul.leave-list span.leave-type {
  color: #1118;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.lv-status-task ul.leave-list span.leave-type {
  color: #666;
}

/* BDAY  */
#bday ul.bday-list li {
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

#bday ul.bday-list li .emp-details {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

#bday ul.bday-list li:last-of-type {
  padding-bottom: 0;
}

#bday ul.bday-list li img.empBday {
  height: 35px;
  width: 35px;
  /* border: 1px solid #eee; */
  border-radius: 50%;
  /* margin-right: 10px; */
  display: inline-block;
  object-fit: cover;
  box-shadow: 0px 0px 2px 1px #ccc;
}

#bday ul.bday-list li p {
  /* display: flex;
  flex-direction: column; */
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  width: 84%;
  padding-left: 10px;
}

#bday ul.bday-list li p span.bemp-designation {
  font-size: 11px;
  font-weight: 400;
  text-transform: capitalize;
}

#bday ul.bday-list li span.emp-bday-dt {
  color: #1118;
  font-size: 14px;
  font-weight: 400;
}

img.bday-popper {
  height: 35px;
  transform: translate(2px, -10px);
  position: absolute;
}
#bday ul.bday-list li p.emp_bday_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0px;
}
#hday ul.hday-list li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

#hday ul.hday-list li:last-of-type {
  padding: 0;
}

#hday ul.hday-list li p.hday-name,
#hday ul.hday-list li span.hday-dt {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

#hday ul.hday-list li span.hday-dt {
  color: #1118;
}

#leave li.leave-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

#leave li.leave-details:last-of-type {
  padding: 0;
}

#leave li.leave-details .leave-dt.tt {
  color: red;
  background: #f213131f;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
  line-height: 38px;
}

#leave li.leave-details .leave-dt.bl {
  color: #12ba12;
  background: #12ba121f;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
  line-height: 38px;
}

#leave li.leave-details .leave-dt.ap {
  color: orange;
  background: #ffa50024;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
  line-height: 38px;
}

#leave .leave-list .leave-details p.leave-name {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

#leave-policy .leave-tbl button.cancel-lv {
  background: transparent;
  border: none;
  display: inline-block;
  margin-left: 1rem;
  color: red;
  cursor: pointer;
}

button.nbl-btn.cancel-lv-btn {
  margin-right: 0rem !important;
}

.req-cancel-lv .modal-footer {
  padding-right: 0;
}

#dashboard .view-grid #trello {
  grid-column: span 9;
  grid-row: 2 / span 2;
  overflow-y: auto;
}

#trello .add-task {
  color: var(--primary-shade);
}

#trello .add-task button {
  text-decoration: none;
  color: var(--primary-shade);
  font-weight: 600;
  background: transparent;
  border: none;
  padding-left: 0.3rem;
}

#trello span {
  vertical-align: text-bottom;
}

#trello .trello-task-tbl table {
  width: 100%;
}
#trello .trello-task-tbl table th.trello-client {
  width: 10%;
}

#trello .trello-task-tbl table th.trello-board {
  width: 20%;
}

/* #trello .trello-task-tbl table th.trello-hrs,
#trello .trello-task-tbl table th.trello-action {
  width: 10%;
} */

#trello .trello-task-tbl table th.trello-task {
  width: 20%;
}

#trello .trello-task-tbl table th.trello-description {
  width: 40%;
}

#trello .trello-task-tbl table thead tr:first-of-type {
  border-bottom: 1px solid #666;
  height: 40px;
  padding-bottom: 10px;
  text-align: center;
  position: sticky;
  top: -15px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 1px #000;
  z-index: 10;
}

#trello table tbody td.task-action {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: inherit;
}

#trello .trello-task-tbl table tbody tr {
  text-align: center;
  height: 43px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  transition: 0.02s all;
}

#trello .trello-task-tbl table tbody tr td {
  padding: 10px 0;
  vertical-align: initial;
  min-height: 62px;
  height: 62px;
  padding-right: 0.2rem;
}

#trello .trello-task-tbl table tbody tr td:first-of-type {
  padding-left: 0.5rem;
}

#trello .trello-task-tbl table tbody tr:nth-of-type(even) {
  background-color: #f1f5ff;
}

#trello .trello-task-tbl table tbody tr:hover {
  cursor: pointer;
  font-weight: 400;
  color: var(--nbl-primary);
}

#trello .trello-task-tbl table tbody tr td span.delete {
  color: #ed143d;
}

#trello .trello-task-tbl table tbody tr td span.edit {
  color: #2aa59a;
}

#trello .norecords-display {
  text-align: center;
  color: #666;
}

#trello .trello-task-tbl thead th {
  text-align: left;
}

#trello .trello-task-tbl thead th:first-of-type {
  padding-left: 0.5rem;
}

#trello .trello-task-tbl tbody td {
  text-align: left;
}

#trello .trello-task-tbl tbody td:last-of-type {
  text-align: left;
  position: relative;
}

#trello .trello-task-tbl a.latest-read-more {
  font-size: 12px !important;
  display: inline-block;
  text-decoration: none;
  color: var(--nbl-primary);
  font-weight: 600;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

#trello .trello-task-tbl a.latest-read-more:hover {
  color: var(--secondary-tone);
}

.modal button.nbl-btn {
  color: #fff;
  border: none;
  background: var(--primary-shade);
  border-radius: 5px;
  padding: 5px 25px;
  margin-right: 2rem;
}

/* Records */

.request-desc textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}
textarea:focus-visible {
  outline: none;
  border: 2px solid #86b7fe;
}
.request_submit_button {
  text-align: right;
}

.request_submit_button input[type="submit"] {
  color: #fff;
  border: none;
  background: var(--primary-shade);
  border-radius: 5px;
  padding: 5px 25px;
  margin-right: 2rem;
}

.request_submit_button input.btn.login-submit {
  margin-right: 0rem;
  margin-top: 1rem;
}

#records .records-wrapper {
  grid-column: span 12;
  padding: 1rem 0 2rem;
}

#records .records-wrapper table {
  width: 100%;
}

#records .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#records table thead tr {
  border-bottom: 0.5px solid #666;
  text-align: center;
  height: 40px;
}

#records table tbody tr {
  height: 40px;
  text-align: center;
}

#records table tbody tr:nth-of-type(even) {
  background: #f1f5ff;
}

#records.main-view .view-grid {
  grid-template-rows: auto;
}

/* Holiday */
#holiday .grid-dashlet,
#reports .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
  grid-template-rows: auto;
}

#holiday table,
#reports table {
  width: 100%;
}

#holiday table thead tr,
#reports table thead tr {
  height: 40px;
  border-bottom: 0.5px solid #666;
}

#holiday table tbody tr,
#reports table tbody tr {
  height: 40px;
}

#holiday table thead tr th:first-of-type,
#holiday table tbody tr td:first-of-type,
#reports table thead tr th:first-of-type,
#reports table tbody tr td:first-of-type {
  padding-left: 1rem;
}

#holiday table thead tr th.h_name {
  width: 75%;
}

#holiday table thead tr th.h_day,
#holiday table thead tr th.h_date {
  width: 15%;
}

#holiday table tbody tr:nth-of-type(even),
#reports table tbody tr:nth-of-type(even) {
  background-color: #f1f5ff;
}
#holiday .holiday-wrapper,
#reports .reports-wrapper {
  grid-column: span 12;
}

/* Employee Info */
#emp-info.main-view .view-grid {
  grid-template-rows: auto auto auto;
}

#emp-info .emp-info-cc.grid-dashlet {
  grid-column: span 12;
  display: flex;
  align-items: center;
}

#emp-info .emp-info-cc.grid-dashlet .emp-left {
  display: flex;
  align-items: center;
  border-right: 1px solid #ccc;
  width: auto;
}

#emp-info .emp-left .emp-img img {
  object-fit: cover;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

#emp-info .emp-left .emp-img {
  padding-right: 1rem;
}

#emp-info .grid-dashlet {
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#emp-info .grid-dashlet h5 {
  padding-left: 1rem;
}

#emp-info .emp-details .emp-role span {
  padding: 0 1rem;
}

#emp-info .emp-details .emp-role span.emp-desg {
  /* border-right: 2px solid var(--main-nbl); */
  text-transform: capitalize;
  display: inline-block;
}

#emp-info .emp-right {
  width: 60%;
  padding-left: 0rem;
}

#emp-info button.emp-info-head-edit {
  background: transparent;
  border: none;
  color: #026402b5;
}

#emp-info .emp-right span.emp-rt-heads {
  width: 40%;
  color: #888;
  display: inline-block;
  text-align: right;
  padding-right: 1rem;
  font-size: 16px;
}

#emp-info .emp-personal-info,
#emp-info .emp-emg-info {
  grid-column: span 6;
  min-height: 455px;
}

#emp-info .emp-personal-info .emp-info-head,
#emp-info .emp-emg-info .emp-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.8px solid #ccc;
  padding-bottom: 10px;
}

#emp-info .emp-personal-info .emp-info-head span.emp-info-head-title,
#emp-info .emp-emg-info .emp-info-head span.emp-info-head-title {
  font-weight: 600;
}

#emp-info .emp-personal-info .emp-info-head span.emp-info-head-edit,
#emp-info .emp-emg-info .emp-info-head span.emp-info-head-edit {
  cursor: pointer;
  position: relative;
  top: -5px;
  right: 10px;
  font-size: 20px;
}

#emp-info span.update-txt {
  font-size: 14px !important;
}

#emp-info .emp-info-body label {
  color: var(--nbl-text);
  font-size: 14px;
  /* font-weight: 600; */
  padding-top: 0.7rem;
  padding-bottom: 0.2rem;
}

#emp-info .emp-info-body {
  padding-top: 0.5rem;
}

#emp-info .emp-info-body fieldset {
  margin-top: 1rem;
}

#emp-info .emp-info-body fieldset legend {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  color: #666;
}

#emp-info .emp-info-body label.curr-addr {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  color: #666;
  padding-bottom: 0.7rem;
}

#emp-info .view-head a.nbl-btn {
  margin-right: 0;
}

#emp-info form input.form-control:focus {
  box-shadow: none;
  outline: none !important;
  /* border: none; */
  /* border-color: transparent; */
  /* border-bottom: 1px solid #ccc; */
  /* border-radius: 0; */
}

/* QSR */
#qsr .qsr-submit,
#qsr .qsr-erp {
  grid-column: span 6;
}

/* #qsr button.nbl-btn {
  margin-top: 1rem;
} */

#qsr form buttn.nbl-btn {
  margin-top: 1rem;
}

#qsr .qsr-erp {
  padding: 0 10px;
}

#wall-fame p.qtr-name {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: var(--nbl-primary);
}

#wall-fame .qtr-wrapper .awardee-details {
  text-align: center;
}

#wall-fame .qtr-wrapper .awardee-details p {
  font-weight: 600;
}

#wall-fame .qtr-wrapper .awardee-details span {
  font-size: 14px;
  white-space: nowrap;
}

.modal .qsr-color {
  color: #f0946d;
}

.add-trello-task .modal-body {
  padding: 0 1rem 1rem;
}

.add-trello-task table tr th:first-of-type,
.add-trello-task table tr td:first-of-type {
  padding-left: 5px;
}

.lv-status-task .modal-body {
  padding: 0 1rem 1rem;
}

.modal .award {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  /* grid-template-rows: 95px; */
  grid-template-rows: 150px;
  grid-gap: 1rem;
}

.modal .award img {
  height: 130px;
  width: auto;
  padding-right: 1rem;
  object-fit: contain;
}

.modal .award p {
  font-weight: 600;
  flex: 2;
  font-size: 16px;
}

.modal .award-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem 4rem;
}

#qsr.main-view .view-grid {
  grid-template-rows: repeat(2, auto);
  grid-gap: 30px;
}

#qsr .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
  min-height: 530px;
  height: auto;
}

#qsr .qsr-submit label {
  font-weight: 600;
}

#wall-fame .qtr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 1rem;
}

#wall-fame .qtr .emp-wrapper {
  padding-bottom: 1rem;
  width: 145px;
}

#wall-fame .awardee-details p {
  margin-bottom: 0;
}

#wall-fame .emp-wrapper img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: inherit;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 5px 5px #bbbbbb42;
  object-fit: cover;
}

#qsr .qsr-submit button.nbl-btn {
  margin-top: 1rem;
}

/* Leave Policy */
.leave_application-form input[name="fromDate"],
.leave_application-form input[name="toDate"] {
  border-radius: 0.25rem;
  padding: 5px;
  border: 1px solid #ced4da;
  outline: none;
}

.leave_application-form input[name="fromDate"]:focus-visible,
.leave_application-form input[name="toDate"]:focus {
  border-color: #86b7fe;
}

form.leave_application-form select:focus,
form.leave_application-form textarea:focus {
  box-shadow: none;
  border-color: #86b7fe;
}

#leave-policy .leave-details {
  display: flex;
  justify-content: space-between;
  grid-column: span 9;
}

#leave-policy .leave-details .leave-info {
  background: #fff;
  flex-basis: 32%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#leave-policy .leave-details .leave-info h5 {
  padding-top: 1rem;
}

#leave-policy .leave-details .leave-info span {
  font-size: 1.4rem;
  padding: 15px;
  display: inline-block;
  /* line-height: 1.6rem; */
  border-radius: 50%;
  margin: 0.5rem;
  height: 65px;
  width: 65px;
  text-align: center;
}

#leave-policy .leave-details .leave-info span.total {
  background: #f213131f;
  color: red;
}

#leave-policy .leave-details .leave-info span.bal {
  color: #12ba12;
  background: #12ba121f;
}

#leave-policy .leave-details .leave-info span.wait-app {
  color: orange;
  background: #ffa50024;
}

#leave-policy .up-leave {
  grid-column: span 3;
}

#leave-policy .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#leave-policy .view-grid {
  grid-template-rows: 170px auto auto;
}

#leave-policy .view-grid-terms {
  grid-template-rows: auto auto;
}

#leave-policy .notice-board {
  grid-column: span 12;
}

#leave-policy .leave-acc {
  grid-column: span 12;
}

#leave-policy .leave-acc-head {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

#leave-policy .leave-acc-head span.toggle-icon {
  display: inline-block;
  margin-right: 1rem;
}

#leave-policy .leave-acc-head h5 {
  margin: 0;
}

/* #leave-policy .leave-acc-body .leave-tbl {
  display: none;
} */

#leave-policy .leave-acc-body table {
  width: 100%;
  padding-top: 25px;
}

#leave-policy .leave-acc-body table thead {
  border-bottom: 1px solid #666;
  width: 100%;
}

#leave-policy .leave-acc-body table thead th.leave-from,
#leave-policy .leave-acc-body table thead th.leave-to {
  width: 20%;
}

#leave-policy .leave-acc-body table thead th.leave-days {
  width: 7%;
}

#leave-policy .leave-acc-body table thead th.leave-action {
  width: 15%;
}

#leave-policy .leave-acc-body table thead th.leave-reason {
  width: 40%;
}

#leave-policy .leave-acc-body table thead tr {
  height: 35px;
}

#leave-policy .leave-acc-body table tbody tr {
  padding: 10px 0;
  height: 40px;
}

.lv-application button.nbl-btn.lv-application {
  margin-right: 1rem;
}

.main-view .nbl-btn {
  background: var(--secondary-tone);
  /* background: #3da4c2; */
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  text-decoration: none;
  margin-top: 1rem;
  margin: 0;
}
/* ------ Team Members ---------- */

#teams .team-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
}

#teams .team-grid .team-mate img.empTeam {
  height: 50px;
  width: 50px;
  border: 1px solid #eee;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  object-fit: cover;
}

#teams .team-grid .emp-details {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 16px;
}

#teams .team-grid .emp-details span.team-emp-name {
  font-weight: 600;
}

#teams .team-grid .emp-details span.team-emp-designation {
  font-size: 14px;
}

#teams .team-grid span.team-mate {
  display: flex;
  align-items: center;
}

#teams .team-grid td.skills-column {
  height: 90px;
}
/* #teams .grid-dashlet {
}

#teams #ctax {
  grid-column: 1/-1;
} */

/* #teams #ctax {
  grid-row: span 3;
} */

#teams #ctax .ctax-head
/* #teams #tdn .tdn-head,
#teams #clg .clg-head,
#teams #ui .ui-head,
#teams #mktg .mktg-head,
#teams #cck .cck-head,
#teams #jtax .jtax-head  */ {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding-bottom: 5px;
  font-weight: 600;
}

/* #teams .grid-dashlet ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

#teams .grid-dashlet ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
} */

.report-title,
.report-title a:hover {
  text-decoration: none !important;
  color: #10567e !important;
  font-weight: 600;
  cursor: pointer;
}

#teams .team-grid td.skills-column {
  height: 90px;
  padding: 0.5rem 0;
}

#teams .team-grid .skills span.skill-type {
  padding-right: 0.4rem;
  display: inline-block;
  width: 85px;
  text-align: right;
  color: #666;
}

#teams .team-grid .skills span.skill-value {
  display: inline-block;
  width: 78%;
  vertical-align: top;
}

#teams .team-grid {
  grid-column: 1/-1;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
}

#teams table {
  width: 100%;
}

#teams .task-filter .filter {
  box-shadow: 2px 2px 2px #1e5a6861;
}

#teams form.select-team {
  width: 20%;
}

#teams table th.name {
  width: 35%;
}

#teams table th.skills {
  width: 55%;
}

#teams table th.contact {
  width: 10%;
}

#teams table thead tr {
  border-bottom: 0.5px solid #666;
  /* text-align: center; */
  height: 40px;
}

#teams table tbody tr {
  height: 40px;
}

#teams table tbody tr:nth-of-type(even) {
  background-color: #f1f5ff;
}

#teams table thead tr th:first-of-type,
#teams table tbody tr td:first-of-type {
  padding-left: 1rem;
}

#teams table tbody tr .contact-mate .call {
  padding-right: 2rem;
}

#teams .team-grid ul li:hover {
  background: #e1eaff;
}

#teams .team-grid .contact-mate {
  color: #7e7e98;
  display: flex;
  justify-content: flex-start;
}

#teams .team-grid .contact-mate a {
  color: inherit;
}

/* LOGIN PAGE */
/* #login {
  height: 100vh;
} */

#login .login-wrapper {
  /* padding: 4rem; */
  height: 100vh;
  align-items: center;
}

#login .login-form,
#login .login-img {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login .login-form form {
  width: 50%;
  margin: 2rem auto;
}

#login .login-wrapper form label {
  width: 100%;
  margin-bottom: 1rem;
}

#login .login-wrapper form input.login-submit {
  width: 100%;
  background: #ef946c;
  color: #fff;
}

#login .login-wrapper .login-form h4.hrm-heading {
  /* margin-top: 2rem; */
  font-size: 16px;
  /* transform: translateX(72px); */
  color: #c05746;
  width: 83%;
  text-align: right;
}

#login .login-wrapper img.hrm-nbl-logo {
  /* height: 30px; */
}
#login .login-wrapper img.hrm-nbl-logo {
  width: 80%;
  display: flex;
  margin: 0 auto;
}
.login_form_wrapper {
  width: 37%;
  text-align: center;
}
#login .login-img {
  background-size: cover;
  height: inherit;
  background-position: center;
  background-repeat: no-repeat;
}

#login .login-form label {
  position: relative;
}

#login .login-form label input {
  padding-left: 3rem;
}

#login .login-form label span.login-icons {
  position: absolute;
  top: 5px;
  left: 15px;
  color: #aaa;
}

/* Task Management */

#manage-tasks .task-filter {
  padding-bottom: 1.5rem;
  position: relative;
}

#manage-tasks div#c_board {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  padding: 0;
  box-shadow: 0px 1.5px 6px #1e5a6861;
}

#manage-tasks div#clientId,
#manage-tasks div#c_task {
  border: none;
  padding: 0;
  box-shadow: 0px 1.5px 6px #1e5a6861;
}

#manage-tasks div#c_board .css-yk16xz-control,
#manage-tasks div#clientId .css-yk16xz-control,
#manage-tasks div#c_task .css-yk16xz-control {
  border: none;
}

#manage-tasks div#c_task.filter {
  position: absolute;
  right: 0px;
}

#manage-tasks .task-filter .filter {
  width: 25%;
  /* box-shadow: 2px 2px 2px #1e5a6861; */
  display: inline-block;
}

select.form-control.filter:focus {
  box-shadow: none;
  border: none;
}

#manage-tasks .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #1e5a6861;
}

#manage-tasks .grid-dashlet table {
  width: 100%;
  max-width: 100%;
}

#manage-tasks .grid-dashlet table thead tr {
  border-bottom: 1px solid #666;
  height: 40px;
  padding-bottom: 10px;
}

#manage-tasks .grid-dashlet table tbody tr {
  height: 70px;
  max-height: 70px;
  vertical-align: top;
}

#manage-tasks .grid-dashlet table tbody tr td {
  height: 60px;
}

#manage-tasks .grid-dashlet table tbody tr input {
  border: none;
  background: #eeeeeee0;
  outline: none;
  width: 98%;
  padding: 4px 6px;
}

#manage-tasks .grid-dashlet table tbody tr input.hr {
  width: 90%;
}

#manage-tasks .grid-dashlet table tbody tr textarea.task-descp {
  width: 99%;
  background: #eeeeeee0;
  height: inherit;
  border: none;
  vertical-align: middle;
  padding: 4px 6px;
}

.add-trello-task table {
  width: 100%;
}

#manage-tasks button.saveTask {
  background: transparent;
  border: none;
  color: green;
  font-size: 22px;
}

#manage-tasks button.updateTask {
  color: blue;
  background: transparent;
  border: none;
  font-size: 22px;
}

#manage-tasks button.saveTask svg,
#manage-tasks button.updateTask svg {
  vertical-align: baseline;
}

#manage-tasks td.task-icons {
  /* display: flex;
  justify-content: center;
  height: inherit; */
  /* transform: translateY(10px); */
  /* padding: 0 20px 0 0; */
  text-align: center;
}

#manage-tasks th.clientth.board {
  width: 12%;
}

#manage-tasks th.board {
  width: 18%;
}

#manage-tasks th.task {
  width: 23.5%;
}

#manage-tasks th.hr {
  width: 6%;
}

#manage-tasks th.desc {
  width: 40%;
}

#manage-tasks th.trello-label {
  width: 26.5%;
}

#manage-tasks th.task-action {
  width: 0%;
}

#manage-tasks .trello-labels select {
  width: 90%;
}

#manage-tasks .trello-labels .trello-labels-multi {
  padding: 0 0.3rem 0.5rem;
  font-size: 16px;
  border: 0;
}

.add-trello-task table thead tr {
  border-bottom: 1px solid #666;
}

.add-trello-task table thead tr th.client {
  width: 10%;
}

.add-trello-task table thead tr th.board {
  width: 20%;
}

.add-trello-task table thead tr th.task {
  width: 30%;
}

.add-trello-task table thead tr th.hr {
  width: 5%;
}

.add-trello-task table thead tr th.desc {
  width: 35%;
}

.add-trello-task table thead tr th.trello-label {
  width: 10%;
}

.add-trello-task table tbody tr:nth-of-type(even) {
  background: #f1f5ff;
}

.add-trello-task table tbody tr {
  height: 50px;
}

.add-trello-task .trello-task-tbl td.trello-updated-descp {
  padding: 0.5rem 0.2rem 0.5rem 0;
}

span.success {
  text-align: center;
  display: block;
  color: green;
  background: #00800021;
  font-weight: 600;
  padding: 5px 0px;
  font-size: 14px;
}

span.error {
  text-align: center;
  display: block;
  color: red;
  background: rgba(255, 0, 0, 0.082);
  font-weight: 600;
  padding: 5px 0px;
  font-size: 14px;
}

div#error-page {
  width: 100%;
  height: 100vh;
  background: url(https://crm.nablasol.net/fromAPI/hrm-404.avif);
  background-size: cover;
  background-position: 0px -270px;
}

div#error-page .err-container {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

#error-page .err-container p {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--nbl-primary);
}

#error-page .err-container h1 {
  font-size: 12rem;
  color: var(--secondary-tone);
  font-weight: 800;
}

#error-page .err-container h5 {
  font-size: 3rem;
  color: var(--nbl-primary);
}

#error-page .err-container a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  padding-top: 5rem;
  font-size: 1.5rem;
}
#login a.forgot-pswd {
  display: block;
  text-align: right;
  font-size: 12px;
  color: var(--primary-shade);
  text-decoration: none;
  cursor: pointer;
}
.successMsg {
  grid-column: span 12;
  text-align: center;
  background: #fff;
  color: green;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid green;
}
.feedback_inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;
}
.feedback_points {
  width: 49%;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader img {
  height: 90px;
}

.lv-application .loader img,
.raise-ticket .loader img,
.change-pswd .loader img {
  height: 50px;
}

.norecords-display {
  text-align: center;
  color: var(--nbl-text);
}

#manage-tasks td.task-mgmt-norecords {
  padding-top: 1rem;
}
/* Modal for custom css */
.modal_title_wrapper img {
  width: 36%;
}
.modal_title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.quote_sec h3 {
  font-size: 26px;
  font-weight: 700;
  color: var(--primary-shade);
  font-family: auto;
}
.quote_sec {
  padding-left: 13px;
  width: 70%;
}
.quote_sec p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}
.modal-content {
  border-radius: 25px;
  border: 1px solid var(--primary-shade);
}
.modal-header button.btn-close {
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 1;
}
.modal-header {
  border-bottom: none;
}
hr.hr_line {
  width: 87%;
  margin: 1rem auto;
  height: 2px;
  background: var(--primary-shade);
  opacity: 1;
}
.leave_type,
.reason_,
.leave_date,
.ticket_desc,
span.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leave_date input {
  width: 100%;
}
.leave_date {
  padding: 0 12px 9px 11px;
}
.leave_type {
  padding-bottom: 9px;
}
.reason_ textarea.form-control {
  padding: 5px;
}
.leave_type select,
.leave_date input {
  height: 50px;
}
form.leave_application-form .modal-body .row {
  flex-direction: column;
}
form.leave_application-form .modal-footer,
.change-pswd .modal-footer,
.request-password .modal-footer,
.reset-password .modal-footer {
  justify-content: center;
}
form.leave_application-form .modal-body {
  padding: 1rem 2rem;
}
form.leave_application-form label {
  width: 25%;
  margin-right: 11px;
}
button#apply-leave,
button#change-pwd {
  width: 32%;
  border-radius: 50px;
  padding: 6px 10px;
  margin: 0;
}
button#req-pswd-btn,
button#reset-pswd-btn {
  width: 50%;
  border-radius: 50px;
  padding: 6px 10px;
  margin: 0;
}
.ticket_desc {
  padding: 0 2rem;
}
.ticket_desc textarea {
  padding: 5px;
  margin-left: 12px;
  border: 1px solid #ccc;
}
.request_submit_button {
  text-align: center;
}
.request_submit_button input.btn.login-submit {
  width: 30%;
  border-radius: 50px;
}
.request_submit_button {
  text-align: center;
  margin-top: 2rem;
  border-top: 1px solid #ccc;
}
.raise-ticket .modal-body {
  padding: 1rem 0;
}
.change-pswd .modal-body .col-md-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.change-pswd label,
span.question label {
  width: 45%;
  margin-right: 10px;
}
.change-pswd input {
  height: 50px;
}
span.question input {
  height: 50px;
}
.request-password .modal-body,
.reset-password .modal-body {
  padding: 0;
}
.request-password .container,
.reset-password .container {
  padding: 0 2rem;
}
.modal-dialog.lv-application.modal-md .successMsg {
  width: 87%;
  margin: 0 auto;
}
/* leave sec */
.leave_calender {
  text-align: end;
}
.leave_calender button {
  border: none;
  background: none;
  padding: 0 16px;
  margin: 0;
  font-size: 22px;
  color: #10567e;
  vertical-align: text-bottom;
  display: flex;
  margin-left: auto;
}
.leave-calendar {
  max-width: 920px;
}
.leave-calendar .modal-content {
  min-height: 569px;
}
.leave-calendar
  .modal-content
  .modal-body
  table.fc-scrollgrid.fc-scrollgrid-liquid
  tbody
  tr.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  height: 455px;
}
.modal-dialog.leave-calendar.modal-md a {
  text-decoration: none;
  color: var(--nbl-primary);
}
.leave_label {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  align-items: center;
  padding: 2px 5px;
  /* margin-bottom: 2px !important; */
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5rem !important;
  width: 96%;
}
.modal-dialog.leave-calendar.modal-md .modal-header {
  padding: 0;
}
.fc .fc-toolbar-title {
  font-size: 26px !important;
}
button.fc-today-button.fc-button.fc-button-primary {
  display: none;
}
.fc .fc-button {
  font-size: 12px !important;
}

/* team member client tasks */
.panel_tabs ul {
  flex-direction: column;
  width: 22%;
  margin-bottom: 0 !important;
  border: none;
}
.panel_tabs ul li button {
  border: none !important;
  border-radius: 0 !important;
  transition: all 0.1s ease;
  color: var(--nbl-primary);
  font-weight: 600;
  width: 100%;
  text-align: left;
  min-height: 43px;
}
li.nav-item:first-of-type {
  padding-right: 1px;
}
.client_view_heading {
  padding-left: 1rem;
}

li.nav-item {
  width: 100%;
  padding: 0;
}
.panel_tabs ul li:hover {
  background-color: transparent !important;
}
.panel_tabs ul li button:hover {
  color: #fff !important;
  background-color: var(--nbl-primary);
}
.nav-tabs .nav-link.active {
  background-color: var(--nbl-primary) !important;
  color: #fff !important;
}
.panel_tabs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.panel_tabs .nav.nav-tabs .nav-item:first-of-type:hover {
  background: var(--nbl-primary) !important;
}
.panel_tabs .tab-content {
  width: 78%;
  border: 2px solid var(--nbl-primary);
}
.emp-details a {
  text-decoration: none;
  color: var(--nbl-primary);
  font-weight: 600;
  cursor: pointer;
}
/* .panel_tabs .nav-item:nth-child(1) button{
  background-color: #008faf;
}
.panel_tabs .nav-item:nth-child(2) button{
  background-color:#12ba12;
}
.panel_tabs .nav-item:nth-child(3) button{
  background-color:rgba(253, 117, 117, 0.925)
}
.panel_tabs .nav-item:nth-child(4) button{
  background-color: #6ad3eb;
}
.panel_tabs .nav-item:nth-child(5) button{
  background-color: #05b379;
}
.panel_tabs .nav-item:nth-child(6) button{
  background-color: #9d44f0;
}
.panel_tabs .nav-item:nth-child(7) button{
  background-color: #eeea02;
}
.panel_tabs .nav-item:nth-child(8) button{
  background-color: #d80142;
}
.panel_tabs .nav-item:nth-child(9) button{
  background-color: #eb9e60;
}
.panel_tabs .nav-item:nth-child(10) button{
  background-color: #6500a0;
}
.panel_tabs .nav-item:nth-child(11) button{
  background-color: #006154;
}
.panel_tabs .nav-item:nth-child(12) button{
  background-color: #cc4d03;
}
.panel_tabs .nav-item:nth-child(13) button{
  background-color: #f1a009;
} */
/* .panel_tabs .nav-item button{
  color: #fff;
} */
.panel_tabs ul li button:hover {
  color: #fff !important;
  background-color: var(--nbl-primary);
}
.nav-tabs .nav-link.active {
  background-color: #e1eaff !important;
  color: #10567e !important;
}
.panel_tabs ul {
  flex-direction: column;
  width: 22%;
  margin-bottom: 0 !important;
  border: 2px solid var(--nbl-primary);
  border-right: none;
  border-top: none;
}
thead tr.panel_table_row {
  background: var(--nbl-primary);
  color: #fff;
}
td.panel_table_data {
  padding-right: 12px;
}
td.panel_table_data:first-of-type {
  font-weight: 600;
  color: var(--nbl-primary);
}
tr.panel_table_row {
  border-bottom: 1px solid #ccc;
}
th.panel_table_heading {
  width: 18%;
}
li.nav-item:first-of-type button {
  color: #fff;
}

li.nav-item:first-of-type {
  background: var(--nbl-primary);
  height: 43px;
}
.client_view_heading {
  padding-left: 1rem;
}
th.panel_table_heading:last-of-type {
  width: 15%;
  padding-right: 10px;
  text-align: center;
}
h3.noData {
  text-align: center;
  color: #10567e;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  transform: translateX(18rem);
  font-size: 22px !important;
  border-top: none !important;
}
div.main-view .view-head a.active,
div.main-view .view-head a {
  text-decoration: none;
  background: var(--nbl-primary);
  color: #fff;
  padding: 6px 13px;
  font-weight: 600;
  border-radius: 6px;
  width: auto;
}
div.main-view .view-head a.active span.view_back_btn {
  display: flex;
  align-items: center;
}
div.main-view .view-head a.active span.view_back_btn svg {
  margin-left: 5px;
}
.label_single {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin: 4px 0;
  border-radius: 4px;
  padding: 7px 0;
  color: #fff;
}
.panel_table_tbody {
  border-top: none !important;
}
/* Birthday Wishes Component Starts */
.card-wrapper {
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 30px;
  padding: 25px 0;
}
.avatar {
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}
.avatar > img {
  height: 100%;
  width: 100%;
}
.avatar-box {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 25px 8px 25px;
}
.profile-name > h3 {
  font-weight: 700;
  letter-spacing: 1px;
}

.bday-banner {
  height: 350px;
}
.bday-banner > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.bottom {
  margin: 0 25px;
}
.icons {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.icon {
  font-size: 35px;
  color: #666;
  cursor: pointer;
}

.liked {
  animation: like 0.2s 1;
  color: #dc0000;
}

.liked-post {
  animation: like 0.2s 1;
  color: #10567e;
}

.comment-box > .input-comment-wrapper > input {
  border: none;
  outline: none;
  font-size: 14px;
  flex: 3;
}
.comment-box > .input-comment-wrapper > button {
  flex: 1;
  padding: 9px 5px;
  border-radius: 6px;
  border: none;
  background-color: #10567e;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.comment > p,
.comment-by {
  margin: 0;
}
.middle {
  padding: 0 8px;
}
.input-comment-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  border: 0.5px solid #5b5b5b;
  padding: 15px 12px;
  border-radius: 8px;
}
.comments-box > h3 {
  color: #666;
  font-size: 15px;
}

.comment-wrapper {
  background-color: #f7f7f7;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.comments-box {
  margin-top: 30px;
}
.comment-text {
  margin-top: 15px;
}
.comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time-stamp > p {
  margin-top: 14px;
  color: #7a7a7a;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 1px;
}
.card-box {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 49px;
  margin-top: 55px;
}

@keyframes like {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes like {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
}
/* Bday Wishes StyleEnds */

@media screen and (min-width: 1200px) and (max-width: 1340px) {
  #sidebar .menu-list-wrapper .menu-list li.listItem a {
    font-size: 14px;
  }

  #sidebar .menu-list-wrapper {
    padding-top: 0.4rem;
  }

  #sidebar h5.emp-name {
    font-size: 16px;
  }

  #sidebar #emp-sect .emp-desig {
    font-size: 13px;
  }

  #sidebar h5 {
    font-size: 17px;
  }

  #primary .header #announcement {
    left: 44%;
    max-width: 50%;
  }

  #announcement h5 {
    font-size: 14px;
  }

  #qsr span.qsr-pts {
    font-size: 14px;
  }

  #manage-tasks .trello-labels .trello-labels-multi {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  #dashboard .view-grid #welcome,
  #dashboard .view-grid #trello {
    grid-column: span 12;
  }

  #dashboard .view-grid #punch,
  #dashboard .view-grid #lvstatus,
  #dashboard .view-grid #bday,
  #dashboard .view-grid #hday,
  #dashboard .view-grid #leave {
    grid-column: span 6;
  }

  .main-view .view-grid {
    grid-auto-rows: auto;
    grid-template-rows: auto auto auto;
  }

  #primary .header h4 {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  
  #sidebar .menu-list-wrapper .menu-list li.listItem a {
    padding-left: 1rem;
  }

  #primary .header {
    padding-bottom: 3rem;
  }

  #primary .header #announcement {
    top: 3.4rem;
  }

  #primary .header h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  div#hamburger {
    padding: 0px 5px;
    height: 36px;
    display: initial;
  }

  .login_form_wrapper {
    width: 60%;
  }
  #sidebar span#close-sidebar {
    height: 20px;
    width: 20px;
    color: var(--secondary-tone);
    display: block;
    position: absolute;
    right: 8%;
    font-size: 24px;
    top: 0.5%;
    display: block;
  }

  .header span.toggle-menu {
    height: 2px;
    width: 30px;
    background: var(--sec-nbl);
    position: relative;
    top: 4px;
    cursor: pointer;
  }

  .header span.toggle-menu::before {
    height: 2px;
    width: 30px;
    background: var(--sec-nbl);
    content: "";
    top: -8px;
    left: 0;
    position: absolute;
  }

  .header span.toggle-menu::after {
    height: 2px;
    width: 30px;
    background: var(--sec-nbl);
    content: "";
    top: 8px;
    left: 0;
    position: absolute;
  }

  #sidebar {
    /* display: none; */
    position: fixed;
    top: 0px;
    left: -100%;
    width: 100%;
    background: #fff;
    max-width: 40%;
    height: 100vh;
    z-index: 999;
    transition: 0.2s all linear;
    box-shadow: 1px 1px 80px #000000b3;
  }

  #primary .header {
    padding: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  #primary .header #announcement {
    /* top: 5.4rem;
    left: 20%;
    transform: translateX(-15%);
    max-width: 80%; */
    position: unset;
    order: 3;
    transform: translateX(0px);
    width: 100%;
    max-width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
  }

  #primary .header #announcement {
    top: 3.4rem;
  }

  #primary .header {
    padding-bottom: 3rem;
  }
  /* #primary {
    height: 100vh;
  } */
}

@media screen and (max-width: 768px) {
  #dashboard .view-grid #welcome,
  #dashboard .view-grid #punch,
  #dashboard .view-grid #lvstatus,
  #dashboard .view-grid #bday,
  #dashboard .view-grid #hday,
  #dashboard .view-grid #leave,
  #dashboard .view-grid #trello {
    grid-column: span 12;
  }
  /* #dashboard .view-grid #trello {
    grid-row-start: 2;
  }

  #dashboard .view-grid #lvstatus {
    grid-row-start: 3;
  } */
  .login_form_wrapper {
    width: 85%;
  }
  #login .login-wrapper img.hrm-nbl-logo {
    width: 60%;
  }
  #trello .trello-task-tbl {
    overflow-x: scroll;
  }

  #trello .trello-task-tbl table {
    width: 200%;
  }

  #trello .trello-task-tbl table tbody tr td {
    padding: 10px 5px 10px 0px;
  }

  #welcome > div {
    display: block !important;
  }

  #dashboard p.greetings {
    margin-bottom: 0.4rem;
  }

  #primary #overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    display: none;
    z-index: 15;
    background-color: #3c3c3c5c;
  }

  #primary .header h4 {
    font-size: 14px;
  }

  #primary .login {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  #primary .login span {
    text-align: right;
    color: var(--nbl-primary);
  }

  #primary .header span {
    padding-left: 0rem;
  }

  #primary .header button {
    padding: 0 0 0 10px;
  }

  #emp-info .emp-personal-info,
  #emp-info .emp-emg-info {
    grid-column: span 12;
  }

  #sidebar {
    max-width: 60%;
  }
}

@media screen and (max-width: 576px) {
  /* div.main-view .view-head {
    display: block;
  } */
  .fc .fc-toolbar-title {
    font-size: 22px !important;
  }
  .fc-button-group {
    margin-right: 12px;
  }
  #bday ul.bday-list li p {
    position: relative;
  }
  .login_form_wrapper {
    width: 100%;
  }
  #login .login-wrapper img.hrm-nbl-logo {
    width: 42%;
  }
  #dashboard #bday img.bday-popper {
    height: 35px;
    transform: translate(2px, -8px);
  }

  #trello .trello-task-tbl table {
    width: 180%;
  }

  #dashboard span.punch-note {
    padding-right: 5px;
  }

  /* #dashboard form {
    margin-top: 1rem;
  } */

  #manage-tasks .grid-dashlet table {
    max-width: 300%;
    width: 300%;
  }

  #teams .team-grid {
    overflow-x: scroll;
  }

  #teams table {
    max-width: 200%;
    width: 200%;
  }

  #primary .header #announcement {
    top: 5.5rem;
  }

  #primary .header #announcement {
    margin-bottom: 1rem;
  }

  #primary span.change-pwd {
    font-size: 14px;
    margin-right: 0px;
  }

  div.main-view {
    padding: 0 15px 3rem;
    /* height: 100vh; */
  }

  #primary .header {
    padding: 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  #primary .header h4 {
    margin-bottom: 0;
    padding-top: 0.2rem;
    font-weight: 400;
  }

  #dashboard form input.punch-hrs {
    width: 100px;
  }

  #dashboard form input.punch-hrs {
    padding: 5px 2px;
  }

  div#dashboard button.punch-btn {
    padding: 5px;
    font-size: 15px;
  }

  .main {
    display: block;
  }

  #sidebar {
    max-width: 75%;
  }

  #sidebar .menu-list-wrapper .menu-list li.listItem a {
    padding: 8px 0 8px 2rem;
  }

  /* Login */
  #login .login-form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  #login .login-img {
    display: none;
  }

  #login .login-form {
    width: 100%;
  }

  #login .login-form form {
    width: 90%;
  }

  #primary {
    z-index: -1;
    overflow-y: auto;
    height: 100vh;
    background-size: cover;
  }

  #primary .login {
    width: 45%;
    display: flex;
    flex-direction: row;
  }

  #primary .header .empImg,
  #primary .header span.logout-text {
    display: none;
  }

  /* Manage Tasks */
  .trello-task-tbl {
    overflow: auto;
  }

  .add-trello-task table {
    width: 200%;
  }

  .add-trello-task table thead tr th.hr {
    width: 7%;
  }

  #manage-tasks .task-filter .filter {
    width: 100%;
  }

  #manage-tasks td.task-mgmt-norecords {
    text-align: left;
  }

  #manage-tasks div#c_board {
    position: relative;
    margin-top: 1rem;
    transform: none;
    left: 0;
  }

  #manage-tasks div#c_task.filter {
    position: unset;
    margin-top: 1rem;
  }

  #manage-tasks table {
    width: 200%;
  }

  #manage-tasks .grid-dashlet {
    overflow-x: auto;
    height: 400px;
  }

  /* Personal */

  #emp-info .emp-info-cc.grid-dashlet {
    display: initial;
    padding: 10px;
  }

  #emp-info .grid-dashlet h5 {
    font-size: 18px;
    text-align: center;
  }

  #emp-info .emp-left .emp-img img {
    height: 60px;
    width: 60px;
  }

  #emp-info .emp-info-cc.grid-dashlet .emp-left {
    width: 100%;
    border-right: none;
    justify-content: center;
  }

  #emp-info .emp-right span.emp-rt-heads {
    width: 38%;
    padding-right: 0.5rem;
    font-size: 14px;
  }

  #emp-info .emp-right span {
    font-size: 14px;
  }

  #emp-info .emp-right {
    width: 100%;
    padding-top: 1.5rem;
  }

  #emp-info .emp-left .emp-img {
    padding-right: 0rem;
  }

  /* Team Members */

  #teams .team-grid {
    overflow-x: scroll;
  }

  #teams table {
    max-width: 300%;
    width: 300%;
  }

  /* Records */
  #records .records-wrapper {
    overflow-x: scroll;
  }

  #records .records-wrapper table {
    width: 250%;
  }

  /* Holiday */
  #holiday table {
    width: 140%;
  }

  #holiday .holiday-wrapper {
    overflow-x: scroll;
  }

  #holiday table thead tr th.h_day,
  #holiday table thead tr th.h_date {
    width: 30%;
  }

  #holiday table thead tr th:last-of-type,
  #holiday table tbody tr td:last-of-type {
    padding-right: 1rem;
  }

  /* Qsr */
  #wall-fame .qtr-wrapper .awardee-details span {
    white-space: initial;
  }

  #qsr .qsr-submit,
  #qsr .qsr-erp {
    grid-column: span 12;
  }

  #leave-policy .leave-details,
  #leave-policy .up-leave {
    grid-column: span 12;
  }

  .modal .award-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .modal .award img {
    padding-right: 0rem;
  }

  .modal .award p {
    font-size: 15px;
    margin-bottom: 0;
  }

  /* Leave */
  #leave-policy .leave-tbl {
    overflow-x: scroll;
  }

  #leave-policy .leave-acc-body table {
    width: 200%;
  }

  #leave-policy .leave-details .leave-info h5 {
    height: 40px;
    font-size: 15px;
  }

  #leave-policy .leave-details .leave-info span {
    height: 50px;
    width: 50px;
    font-size: 18px;
    line-height: 25px;
    padding: 10px;
  }
  .feedback_inner {
    flex-direction: column;
  }
  .feedback_points {
    width: 100%;
  }
  .change-pswd .modal-body .col-md-12 {
    flex-direction: column;
    align-items: start;
  }
  button#apply-leave,
  button#change-pwd,
  .request_submit_button input.btn.login-submit {
    width: 40%;
    padding: 10px;
  }
  .leave_type,
  .reason_,
  .leave_date,
  .ticket_desc,
  span.question {
    flex-direction: column;
    align-items: start;
  }
  .ticket_desc textarea {
    margin-left: 0;
  }
  form.leave_application-form label {
    width: 100%;
    margin-top: 11px;
  }
}
@media (max-width: 440px) {
  #login .login-wrapper img.hrm-nbl-logo {
    width: 60%;
  }
}
@media (max-width: 992px) {
  #primary {
    height: 100vh;
  }

  .panel_tabs {
    flex-direction: column;
  }
  .panel_tabs ul {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  li.nav-item {
    width: 32%;
    margin-bottom: 4px;
    margin-right: 10px;
  }
  .panel_tabs .tab-content {
    width: 100%;
  }
  .team-grid.client_view_sec {
    padding: 10px !important;
  }
}
@media (max-width: 768px) {
  li.nav-item {
    width: 42%;
  }
}
@media (max-width: 678px) {
  #rpt.main-view .view-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 576px) {
  li.nav-item {
    width: 49%;
    margin-right: 0;
  }
  table.panel_table.table {
    width: 180% !important;
    max-width: 180% !important;
  }
}
@media (max-width: 400px) {
  #rpt.main-view .view-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  li.nav-item {
    width: 100%;
    margin-right: 0;
  }
}

/* Added by Sajitha - For MUI Datatable Header Capitize*/
.mui-datatables-1w1rijm-MuiButtonBase-root-MuiButton-root {
  padding: 0px !important;
  text-transform: capitalize !important;
  text-align: left;
}

.mui-datatables-1ex1afd-MuiTableCell-root {
  padding: 10px 8px;
}

#rpt .grid-dashlet {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #1e5a6861;
  /* min-height: 80px;
  height: 80px; */
}

#rpt span.labelicon {
  padding-right: 0.5rem;
  font-size: 1.8rem;
}

#rpt.main-view .view-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: initial;
  grid-gap: 15px;
}

.qtr-wrapper {
  display: flex;
  align-items: center;
}
